import React, { useEffect, useState } from "react";
import "./style.scss";
import Logo from "../../assets/common/Logo.svg";
import ArrowDown from "../../assets/common/arrowDown.webp";
import menuIcon from "../../assets/common/menuIcon.webp";
import crossIcon from "../../assets/common/crossIcon.webp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import ContactUsComponent from "../ContactUs";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);

  const getMobileMenu = () => {
    return (
      <div className="mobileMenuContainer">
        <div className="mobileMenu_header">
          <div className="logo_wrapper">
            <img src={Logo} alt="logo" />
          </div>
          <div className="closeIconWrapper">
            <img
              src={crossIcon}
              alt="crossIcon"
              onClick={() => setShowMobileMenu(false)}
            />
          </div>
        </div>
        <div className="links_wrapper">
          <button
            className="solution"
            onClick={() => setShowSubMenu(!showSubMenu)}
          >
            <div className="solution_wrapper">
              <span>Solution</span>
              <div className={`arrow_icon_wrapper ${showSubMenu ? "up" : ""}`}>
                <img src={ArrowDown} alt="arrowDown" className="arrow_down" />
              </div>
            </div>
            {showSubMenu && (
              <div className="solution_menu">
                <button
                  onClick={() => {
                    flowEditRedirection();
                    // setShowSubMenu(false);
                    setShowMobileMenu(false);
                  }}
                >
                  FlowEdit
                </button>
                <button
                  onClick={() => {
                    navigate("/comprehensive-editing");
                    setShowMobileMenu(false);
                  }}
                >
                  Comprehensive Editing
                </button>
                <button
                  onClick={() => {
                    window.location.href = "https://modelclipper.imageedit.ai/";
                    setShowMobileMenu(false);
                  }}
                >
                  Remove Model Background
                </button>
              </div>
            )}
          </button>

          <div className="break" />
          <button
            className="pricing"
            onClick={() => {
              calculatorRedirection();
              setShowMobileMenu(false);
            }}
          >
            Pricing
          </button>
          {/* <div className="break" />
          <Link to="">About Us</Link> */}
          <div className="break" />
          <button
            className="scheduleCallBtn"
            onClick={(e) => {
              e.preventDefault();
              setShowContact(true);
              setShowMobileMenu(false);
            }}
          >
            Schedule a call
          </button>
        </div>
      </div>
    );
  };

  const getContactUsModal = () => {
    return (
      <Modal
        // title="Basic Modal"
        open={showContact}
        footer={null}
        width="85%"
        height={"100%"}
        className="contact_us_modal_wrapper"
        onCancel={() => setShowContact(false)}
      >
        <ContactUsComponent
          closeModal={() => setShowContact(false)}
          isModal={true}
        />
      </Modal>
    );
  };

  const flowEditRedirection = () => {
    const flowEdit = document.getElementById("flow_edit");

    if (flowEdit) {
      flowEdit.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.pathname === "/") {
      // Scroll to flow_edit section if you're already on the homepage
      const flowEdit = document.getElementById("flow_edit");
      if (flowEdit) {
        flowEdit.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/1");
    }
  };

  const calculatorRedirection = () => {
    const calculator = document.getElementById("calculator_section");
    if (calculator) {
      calculator.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.pathname === "/") {
      if (calculator) {
        calculator.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/2");
    }
  };

  return (
    <>
      <div className="header_container">
        <div className="header_content">
          <div className="logo_wrapper">
            <img
              src={Logo}
              alt="logo"
              onClick={() => navigate("/")}
              loading="lazy"
            />
          </div>
          <div className="right_wrapper">
            <button
              className="solution"
              onClick={() => setShowSubMenu(!showSubMenu)}
              onMouseEnter={() => setShowSubMenu(true)}
            >
              <span>Solution</span>
              <div className={`arrow_icon_wrapper ${showSubMenu ? "up" : ""}`}>
                <img
                  src={ArrowDown}
                  alt="arrowDown"
                  className="arrow_down"
                  loading="lazy"
                />
              </div>
            </button>
            {showSubMenu && (
              <div
                className="solution_menu"
                onMouseLeave={() => setShowSubMenu(false)}
              >
                <button
                  onClick={() => {
                    flowEditRedirection();
                    setShowSubMenu(false);
                  }}
                >
                  FlowEdit
                </button>
                <button
                  onClick={() => {
                    navigate("/comprehensive-editing");
                    setShowSubMenu(false);
                  }}
                >
                  Comprehensive Editing
                </button>
                <button
                  onClick={() => {
                    window.location.href = "https://modelclipper.imageedit.ai/";
                    setShowMobileMenu(false);
                  }}
                >
                  Remove Model Background
                </button>
              </div>
            )}
            <button
              className="pricing"
              onClick={() => {
                calculatorRedirection();
                setShowSubMenu(false);
              }}
            >
              Pricing
            </button>
            {/* <Link to="">About Us</Link> */}
            <button
              className="scheduleCallBtn"
              onClick={(e) => {
                e.preventDefault();
                setShowContact(true);
              }}
            >
              Schedule a call
            </button>
          </div>
          <div className="menuIconWrapper">
            <img
              src={menuIcon}
              alt="menuIcon"
              onClick={() => setShowMobileMenu(true)}
              loading="lazy"
            />
          </div>
        </div>
      </div>
      {showMobileMenu && getMobileMenu()}
      {showContact && getContactUsModal()}
    </>
  );
};
export default Header;
