import React, { useEffect, useState } from "react";
import "./style.scss";
import { Select, Radio, Modal } from "antd";
import ContactUsComponent from "../../../common/ContactUs";

import EnhanceModelBasic from "../../../assets/Calculator/Enhance_Mode_Images/Basic.webp";
import EnhanceModelEssential from "../../../assets/Calculator/Enhance_Mode_Images/Essential.webp";
import EnhanceModelPro from "../../../assets/Calculator/Enhance_Mode_Images/Pro.webp";

import EnhanceProductBasic from "../../../assets/Calculator/Enhance_Product_Images/Basic.webp";
import EnhanceProductEssential from "../../../assets/Calculator/Enhance_Product_Images/Essential.webp";
import EnhanceProductPro from "../../../assets/Calculator/Enhance_Product_Images/Pro.webp";

import CreateGhostBasic from "../../../assets/Calculator/Create_Ghost_Images/Basic.webp";
import CreateGhostEssential from "../../../assets/Calculator/Create_Ghost_Images/Essential.webp";
import CreateGhostPro from "../../../assets/Calculator/Create_Ghost_Images/Pro.webp";

import CreateFlatLayBasic from "../../../assets/Calculator/Create_Flat_Lay_Images/Basic.webp";
import CreateFlatLayEssential from "../../../assets/Calculator/Create_Flat_Lay_Images/Essential.webp";
import CreateFlatLayPro from "../../../assets/Calculator/Create_Flat_Lay_Images/Pro.webp";

import FabricChangeBasic from "../../../assets/Calculator/Fabric_Changes/Basic.webp";

import JewelleryBasic from "../../../assets/Calculator/Enhance_Jewellery_Images/Basic.webp";
import JewelleryEssential from "../../../assets/Calculator/Enhance_Jewellery_Images/Essential.webp";
import JewelleryPro from "../../../assets/Calculator/Enhance_Jewellery_Images/Pro.webp";

import GhostCreationBasic from "../../../assets/Calculator/Ghost_Creation_Sheer_Garment/Basic.webp";
import GhostCreationEssential from "../../../assets/Calculator/Ghost_Creation_Sheer_Garment/Essential.webp";
import GhostCreationPro from "../../../assets/Calculator/Ghost_Creation_Sheer_Garment/Pro.webp";

import RemoveBgSimple from "../../../assets/Calculator/Transform_Image_Bg_Images/Remove_Background/Simple.webp";
import RemoveBgMedium from "../../../assets/Calculator/Transform_Image_Bg_Images/Remove_Background/Medium.webp";
import RemoveBgComplex from "../../../assets/Calculator/Transform_Image_Bg_Images/Remove_Background/Complex.webp";

import KeepBgSimple from "../../../assets/Calculator/Transform_Image_Bg_Images/Keep_Original_Bg/Simple.webp";
import KeepBgMedium from "../../../assets/Calculator/Transform_Image_Bg_Images/Keep_Original_Bg/Medium.webp";
import KeepBgComplex from "../../../assets/Calculator/Transform_Image_Bg_Images/Keep_Original_Bg/Complex.webp";

import ChangeBgSimple from "../../../assets/Calculator/Transform_Image_Bg_Images/ChangeBackground/Simple.webp";
import ChangeBgMedium from "../../../assets/Calculator/Transform_Image_Bg_Images/ChangeBackground/Medium.webp";
import ChangeBgComplex from "../../../assets/Calculator/Transform_Image_Bg_Images/ChangeBackground/Complex.webp";

import SinglePathSimple from "../../../assets/Calculator/Create_Clipping_Path/Single_Path/Simple.webp";
import SinglePathMedium from "../../../assets/Calculator/Create_Clipping_Path/Single_Path/Medium.webp";
import SinglePathComplex from "../../../assets/Calculator/Create_Clipping_Path/Single_Path/Complex.webp";

import MultiplePathSimple from "../../../assets/Calculator/Create_Clipping_Path/Multi_Path/Simple.webp";
import MultiplePathMedium from "../../../assets/Calculator/Create_Clipping_Path/Multi_Path/Medium.webp";
import MultiplePathComplex from "../../../assets/Calculator/Create_Clipping_Path/Multi_Path/Complex.webp";

import CreateColorwaysSimple from "../../../assets/Calculator/Color_Solution/Create_Colorways/Simple.webp";
import CreateColorwaysComplex from "../../../assets/Calculator/Color_Solution/Create_Colorways/Complex.webp";

import EnhanceColorSimple from "../../../assets/Calculator/Color_Solution/Enhance_Color/Simple.webp";
import EnhanceColorComplex from "../../../assets/Calculator/Color_Solution/Enhance_Color/Complex.webp";

import MatchColorSimple from "../../../assets/Calculator/Color_Solution/Match_Color/Simple.webp";
import MatchColorComplex from "../../../assets/Calculator/Color_Solution/Match_Color/Complex.webp";

const Calculator = () => {
  const [noOfImages, setNoOfImages] = useState(1);
  const [totalAmount, setTotalAmount] = useState(470);
  const [selectedSolution, setSelectedSolution] = useState(1);
  const [subSelectedSolution, setSubSelectedSolution] = useState(1);
  const [planType, setPlanType] = useState(1);
  const [pricePerUnit, setPricePerUnit] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [description, setDescription] = useState("");
  const [data] = useState([
    {
      id: 1,
      title: "Enhance Model Images",
      pricesTitle: "Packages",
      prices: [
        {
          price_id: 1,
          priceType: "Basic",
          price: "0.12",
          images: EnhanceModelBasic,
          description:
            "Removal of moles, blemishes, scars, and bruises to ensure a polished and flawless look for your model images.",
        },
        {
          price_id: 2,
          priceType: "Essential",
          price: "0.47",
          images: EnhanceModelEssential,
          description:
            "Services include all the features of the Basic Plan, plus additional enhancements like removing stray hair, tattoos, and wrinkles to create a cleaner, polished look.",
        },
        {
          price_id: 3,
          priceType: "Pro",
          price: "0.82",
          images: EnhanceModelPro,
          description:
            "Includes everything from the Essential Plan, with added features such as tan removal, skin tone matching, skin smoothening, and shape correction (liquify) for a flawless, professionally retouched appearance.",
        },
      ],
      subTitle: [],
    },
    {
      id: 2,
      title: "Enhance Product Images",
      pricesTitle: "Packages",
      prices: [
        {
          price_id: 1,
          priceType: "Basic",
          price: "0.14",
          images: EnhanceProductBasic,
          description:
            "Normal dust cleaning and retouching for pastings such as in bags, shoes, and similar products. This ensures your products look clean and presentable with minimal touch-ups.",
        },
        {
          price_id: 2,
          priceType: "Essential",
          price: "0.35",
          images: EnhanceProductEssential,
          description:
            "Includes all Basic Plan features plus product reshaping to ensure accurate and appealing product presentation.",
        },
        {
          price_id: 3,
          priceType: "Pro",
          price: "0.56",
          images: EnhanceProductPro,
          description:
            "Everything from the Essential Plan, with the added benefit of stacking multiple images to create one cohesive, high-quality image.",
        },
      ],
      subTitle: [],
    },
    {
      id: 3,
      title: "Create Ghost Images",
      pricesTitle: "Packages",
      prices: [
        {
          price_id: 1,
          priceType: "Basic",
          price: "0.47",
          images: CreateGhostBasic,
          description:
            "Includes neck insert and shape correction to create seamless ghost mannequin images for clothing items.",
        },
        {
          price_id: 2,
          priceType: "Essential",
          price: "0.94",
          images: CreateGhostEssential,
          description:
            "All Basic Plan features, plus left/right match, front/back match, unwanted wrinkle removal, and garment retouching (dust and packaging crease removal).",
        },
        {
          price_id: 3,
          priceType: "Pro",
          price: "2.35",
          images: CreateGhostPro,
          description:
            "Includes everything from the Essential Plan, with the added refinement of perfect symmetry for components like buttons and pockets, as well as seam line symmetry.",
        },
      ],
      subTitle: [],
    },
    {
      id: 4,
      title: "Create Flat lay Images",
      pricesTitle: "Packages",
      prices: [
        {
          price_id: 1,
          priceType: "Basic",
          price: "0.47",
          images: CreateFlatLayBasic,
          description:
            "Includes shape correction and removal of bumps on the outer boundary for a clean, professional look.",
        },
        {
          price_id: 2,
          priceType: "Essential",
          price: "0.94",
          images: CreateFlatLayEssential,
          description:
            "All Basic Plan features, plus left/right match, front/back match, unwanted wrinkle removal, and garment retouching (dust and packaging crease removal).",
        },
        {
          price_id: 3,
          priceType: "Pro",
          price: "2.35",
          images: CreateFlatLayPro,
          description:
            "Everything from the Essential Plan, with the added precision of perfect symmetry for components like buttons and pockets, along with seam line symmetry.",
        },
      ],
      subTitle: [],
    },
    {
      id: 5,
      title: "Fabric Change",
      pricesTitle: "Packages",
      prices: [
        {
          price_id: 1,
          priceType: "Basic",
          price: "4.24",
          images: FabricChangeBasic,
          description:
            "Change the fabric or texture of the garment to reflect different styles or material options, providing versatility without new photography.",
        },
        // {
        //   price_id: 2,
        //   priceType: "Essential",
        //   price: "0.94",
        // },
        // {
        //   price_id: 3,
        //   priceType: "Pro",
        //   price: "2.35",
        // },
      ],
      subTitle: [],
    },
    {
      id: 6,
      title: "Enhance Jewellery Images",
      pricesTitle: "Packages",
      prices: [
        {
          price_id: 1,
          priceType: "Basic",
          price: "0.71",
          images: JewelleryBasic,
          description:
            "Basic retouching and cleaning to enhance the overall appearance of your jewelry images.",
        },
        {
          price_id: 2,
          priceType: "Essential",
          price: "1.2",
          images: JewelleryEssential,
          description:
            "All Basic Plan features, plus reflection retouching and color enhancement for a more vibrant and polished look.",
        },
        {
          price_id: 3,
          priceType: "Pro",
          price: "2.12",
          images: JewelleryPro,
          description:
            "Includes everything from the Essential Plan, with the added benefit of stacking multiple shots to create a single, flawless image.",
        },
      ],
      subTitle: [],
    },
    {
      id: 7,
      title: "Ghost creation for Sheer Garment",
      pricesTitle: "Packages",
      prices: [
        {
          price_id: 1,
          priceType: "Basic",
          price: "1.41",
          images: GhostCreationBasic,
          description:
            "Includes neck insert, shape correction, and filling transparency to create a seamless look for sheer garments.",
        },
        {
          price_id: 2,
          priceType: "Essential",
          price: "2.47",
          images: GhostCreationEssential,
          description:
            "All Basic Plan features, plus maintaining transparency to ensure the garment’s delicate fabric is properly showcased.",
        },
        {
          price_id: 3,
          priceType: "Pro",
          price: "3.53",
          images: GhostCreationPro,
          description:
            "Everything from the Essential Plan, with the added benefit of maintaining high transparency to preserve the light, airy look of sheer garments.",
        },
      ],
      subTitle: [],
    },
    {
      id: 8,
      title: "Transform Image Background Images",
      pricesTitle: "Complexity",
      subTitle: [
        {
          sub_id: 1,
          sub_title: "Remove Background",
          prices: [
            {
              price_id: 1,
              priceType: "Simple",
              price: "0.14",
              images: RemoveBgSimple,
              description:
                "Remove the background to create clean, transparent background images, ready for any platform.",
            },
            {
              price_id: 2,
              priceType: "Medium",
              price: "1.06",
              images: RemoveBgMedium,
              description:
                "Same as Basic—remove the background to create transparent background images for professional use.",
            },
            {
              price_id: 3,
              priceType: "Complex",
              price: "4.71",
              images: RemoveBgComplex,
              description:
                "Same as Essential—remove the background to create transparent background images with precision.",
            },
          ],
        },
        {
          sub_id: 2,
          sub_title: "Keep Original Background",
          prices: [
            {
              price_id: 1,
              priceType: "Simple",
              price: "0.24",
              images: KeepBgSimple,
              description:
                "Clean and retain the original shoot background, ensuring a polished and professional look.",
            },
            {
              price_id: 2,
              priceType: "Medium",
              price: "0.52",
              images: KeepBgMedium,
              description:
                "Same as Simple—clean and keep the original shoot background for a refined finish.",
            },
            {
              price_id: 3,
              priceType: "Complex",
              price: "5.18",
              images: KeepBgComplex,
              description:
                "Same as Medium—clean and maintain the original shoot background with attention to detail.",
            },
          ],
        },
        {
          sub_id: 3,
          sub_title: "Change Background",
          prices: [
            {
              price_id: 1,
              priceType: "Simple",
              price: "0.21",
              images: ChangeBgSimple,
              description:
                "Change the background to a solid color, providing a clean, uniform appearance.",
            },
            {
              price_id: 2,
              priceType: "Medium",
              price: "1.41",
              images: ChangeBgMedium,
              description:
                "Same as Simple—change the background to a solid color for consistency across images.",
            },
            {
              price_id: 3,
              priceType: "Complex",
              price: "5.18",
              images: ChangeBgComplex,
              description:
                "Same as Medium—change the background to a solid color with precision for professional results.",
            },
          ],
        },
      ],
    },
    {
      id: 9,
      title: "Create Clipping Path",
      pricesTitle: "Complexity",
      subTitle: [
        {
          sub_id: 1,
          sub_title: "Single Path",
          prices: [
            {
              price_id: 1,
              priceType: "Simple",
              price: "0.21",
              images: SinglePathSimple,
              description:
                "Create a single, tight path around the subject for precise image editing.",
            },
            {
              price_id: 2,
              priceType: "Medium",
              price: "1.41",
              images: SinglePathMedium,
              description:
                "Same as Simple—create a single, tight path around the subject for accurate editing.",
            },
            {
              price_id: 3,
              priceType: "Complex",
              price: "5.18",
              images: SinglePathComplex,
              description:
                "Same as Medium—create a single, tight path around the subject for high-detail editing.",
            },
          ],
        },
        {
          sub_id: 2,
          sub_title: "Multi Path",
          prices: [
            {
              price_id: 1,
              priceType: "Simple",
              price: "0.315",
              images: MultiplePathSimple,
              description:
                "Create multiple paths for different components of the subject, allowing for precise and flexible editing.",
            },
            {
              price_id: 2,
              priceType: "Medium",
              price: "2.115",
              images: MultiplePathMedium,
              description:
                "Same as Simple—create multiple paths for various components of the subject for enhanced accuracy.",
            },
            {
              price_id: 3,
              priceType: "Complex",
              price: "7.77",
              images: MultiplePathComplex,
              description:
                "Same as Medium—create multiple paths for intricate components of the subject, ideal for detailed editing.",
            },
          ],
        },
      ],
    },
    {
      id: 10,
      title: "Color Solution",
      pricesTitle: "Complexity",
      subTitle: [
        {
          sub_id: 3,
          sub_title: "Enhance Color",
          prices: [
            {
              price_id: 1,
              priceType: "Simple",
              price: "0.21",
              images: EnhanceColorSimple,
              description:
                "Enhance the color of a simple-colored object or garment to improve visual appeal and clarity.",
            },
            // {
            //   price_id: 2,
            //   priceType: "Medium",
            //   price: "0.94",
            // },
            {
              price_id: 3,
              priceType: "Complex",
              price: "0.35",
              images: EnhanceColorComplex,
              description:
                "Enhance the color of a patterned or multicolored object or garment for better visualization and vibrant presentation.",
            },
          ],
        },
        {
          sub_id: 1,
          sub_title: "Match Color",
          prices: [
            {
              price_id: 1,
              priceType: "Simple",
              price: "0.42",
              images: MatchColorSimple,
              description:
                "Match the color of a simple-colored object or garment with a reference for accurate color consistency.",
            },
            // {
            //   price_id: 2,
            //   priceType: "Medium",
            //   price: "0.94",
            // },
            {
              price_id: 3,
              priceType: "Complex",
              price: "1.06",
              images: MatchColorComplex,
              description:
                "Match the color of a patterned or multicolored object or garment with a reference for accurate color matching.",
            },
          ],
        },
        {
          sub_id: 2,
          sub_title: "Create Colorways",
          prices: [
            {
              price_id: 1,
              priceType: "Simple",
              price: "0.56",
              images: CreateColorwaysSimple,
              description:
                "Create different colorways of a simple-colored garment or object using pantone or swatch for accurate color variations.",
            },
            // {
            //   price_id: 2,
            //   priceType: "Medium",
            //   price: "0.94",
            // },
            {
              price_id: 3,
              priceType: "Complex",
              price: "1.18",
              images: CreateColorwaysComplex,
              description:
                "Create different colorways of a patterned or multicolored garment or object using pantone or swatch for detailed color customization.",
            },
          ],
        },
      ],
    },
  ]);
  useEffect(() => {
    const selectedData = data.find((item) => item.id === selectedSolution);
    let price = 0;
    let img;
    let desc;
    if (selectedData) {
      if (selectedData.subTitle.length > 0) {
        const selectedSub = selectedData.subTitle.find(
          (sub) => sub.sub_id === subSelectedSolution
        );
        if (selectedSub) {
          const selectedPrice = selectedSub.prices.find(
            (price) => price.price_id === planType
          );
          price = selectedPrice ? parseFloat(selectedPrice.price) : 0;
          img = selectedPrice?.images;
          desc = selectedPrice?.description;
        }
      } else {
        const selectedPrice = selectedData.prices.find(
          (price) => price.price_id === planType
        );
        price = selectedPrice ? parseFloat(selectedPrice.price) : 0;
        img = selectedPrice?.images;
        desc = selectedPrice?.description;
      }
    }
    setSelectedImage(img);
    setTotalAmount((noOfImages * price).toFixed(2));
    setPricePerUnit(price);
    setDescription(desc);
  }, [
    selectedSolution,
    subSelectedSolution,
    planType,
    noOfImages,
    data,
    description,
  ]);

  const getSubSelectedSolution = () => {
    let filteredData = data.filter((x) => {
      return x.id === selectedSolution;
    });
    return filteredData[0];
  };
  const getPriceType = () => {
    let filterData = data
      .filter((x) => x.id === selectedSolution)[0]
      ?.subTitle.filter((y) => y.sub_id === subSelectedSolution)[0];

    return filterData;
  };
  const getContactUsModal = () => {
    return (
      <Modal
        // title="Basic Modal"
        open={isModalOpen}
        footer={null}
        width="85%"
        height="90dvh"
        className="contact_us_modal_wrapper"
        onCancel={() => setIsModalOpen(false)}
      >
        <ContactUsComponent
          closeModal={() => setIsModalOpen(false)}
          isModal={true}
        />
      </Modal>
    );
  };
  return (
    <div className="calculator_main_container" id="calculator_section">
      <div className="calculator_content">
        <div className="heading">
          <h1>Choose the plan that suits your requirement</h1>
        </div>
        <div className="calculator_container">
          <div className="calculator_content">
            <div className="left_wrapper">
              <div className="heading_wrapper">
                <h2>Enhance Model Image</h2>
              </div>
              <div className="sub_heading">
                <h4>Basic package</h4>
                <div className="cost_wrapper">${pricePerUnit}/image</div>
              </div>
              <div className="images_wrapper">
                {/* <div className="before_image_wrapper">
                  <img src={BeforeImg} alt="beforeImg" />
                </div>
                <div className="after_image_wrapper">
                  <img src={AfterImg} alt="afterImg" />
                </div> */}
                <img src={selectedImage} alt="selectedImage" loading="lazy" />
              </div>
              <div className="description_wrapper">
                <p>{description}</p>
              </div>
            </div>
            <div className="break" />
            <div className="right_wrapper">
              <div className="right_wrapper_content">
                <form>
                  <div className="form_control">
                    <label>Solution</label>
                    <Select
                      className="select_wrapper"
                      optionFilterProp="label"
                      onChange={(value) => {
                        setSelectedSolution(value);
                        setSubSelectedSolution(1);
                      }}
                      value={selectedSolution}
                    >
                      {data.map((data, index) => {
                        return (
                          <Select.Option value={data.id}>
                            {data.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {getSubSelectedSolution().subTitle.length > 0 && (
                      <Select
                        className="select_wrapper"
                        optionFilterProp="label"
                        onChange={(value) => setSubSelectedSolution(value)}
                        value={subSelectedSolution}
                      >
                        {getSubSelectedSolution().subTitle.map(
                          (solution, index) => {
                            return (
                              <Select.Option value={solution.sub_id}>
                                {solution.sub_title}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    )}
                  </div>
                  <div className="form_control">
                    <label>{getSubSelectedSolution().pricesTitle}</label>
                    <Radio.Group
                      buttonStyle="solid"
                      className="radio_wrapper"
                      value={planType}
                      onChange={(e) => setPlanType(e.target.value)}
                    >
                      {getSubSelectedSolution().subTitle.length > 0
                        ? getPriceType()?.prices.map((price) => {
                            return (
                              <Radio.Button
                                value={price.price_id}
                                className="radioBtn"
                                key={price.price_id}
                              >
                                {" "}
                                {price.priceType}
                              </Radio.Button>
                            );
                          })
                        : getSubSelectedSolution()?.prices.map(
                            (price, index) => (
                              <Radio.Button
                                value={price.price_id}
                                className="radioBtn"
                                key={price.price_id}
                              >
                                {" "}
                                {price.priceType}
                              </Radio.Button>
                            )
                          )}
                    </Radio.Group>
                  </div>
                  <div className="form_control">
                    <label>Number of Images</label>
                    <div className="input_wrapper">
                      <button
                        className="icon_wrapper"
                        onClick={(e) => {
                          e.preventDefault();
                          setNoOfImages((prev) => (prev > 1 ? prev - 1 : prev));
                        }}
                      >
                        -
                      </button>
                      <input
                        className="input"
                        value={noOfImages}
                        onChange={(e) => setNoOfImages(e.target.value)}
                        type="number"
                        min="1"
                      />
                      <button
                        className="icon_wrapper"
                        onClick={(e) => {
                          e.preventDefault();
                          setNoOfImages((prev) => prev + 1);
                        }}
                      >
                        +
                      </button>
                    </div>
                    <div className="price_container">
                      <label>Total Estimated Price</label>
                      <div className="price_wrapper">${totalAmount}</div>
                    </div>
                  </div>
                  <div className="action_btn_wrapper">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setIsModalOpen(true);
                      }}
                    >
                      Proceed To Next
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && getContactUsModal()}
    </div>
  );
};
export default Calculator;
