import React, { useEffect, useState } from "react";
import Router from "../../routes";
import Footer from "../Footer";
import "./style.scss";
import Header from "../Header";
import { useLocation } from "react-router-dom";
import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
const Layout = () => {
  const location = useLocation();
  const baseUrlApp = "https://api-leads.flixstock.com";
  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname != "/2" && location.pathname != "/1") {
      console.log("scrolls");
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    const fetchDataAndPostLead = async () => {
      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const res = result.visitorId;
        // const queryParams = getQueryParams(window.location.href);
        const currentQueryParams = getQueryParams(window.location.href);
        const savedQueryParams = JSON.parse(localStorage.getItem("leadQuery"));
        if (
          Object.keys(currentQueryParams).length &&
          (!savedQueryParams ||
            JSON.stringify(savedQueryParams) !==
              JSON.stringify(currentQueryParams))
        ) {
          postLeadCreation(currentQueryParams, res);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchDataAndPostLead();
  }, []);

  function postLeadCreation(data, id) {
    let payload = {
      type: "visit",
      clientUrl: window.location.href,
      feData: {
        visitorId: id,
      },
    };
    if (data?.ref) {
      payload["ref"] = data?.ref;
      delete data["ref"];
    }
    if (data?.id) {
      payload["id"] = data?.id;
      delete data["id"];
    }
    payload["queryData"] = { ...data };
    try {
      axios
        .post(`${baseUrlApp}/api/limited/v1/lead`, payload)
        .then((resp) => {
          window.history.replaceState(null, "", location.pathname);
          localStorage.setItem("leadId", resp?.data?.data?._id);
          localStorage.setItem("leadQuery", JSON.stringify(data));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }
  function getQueryParams(url) {
    const queryParams = {};
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    for (const [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    console.log(queryParams);
    return queryParams;
  }

  const getHeader = () => {
    return (
      <div className="header_main_wrapper">
        <Header />
      </div>
    );
  };
  return (
    <div className="layout_wrapper">
      {getHeader()}
      <Router />
      <Footer />
    </div>
  );
};
export default Layout;
